<template>
  <div class="exam-detail">
    <div class="bg"></div>
    <div class="container main">
      <div class="module1">
        <div class="top">
          <div class="label">
            <div class="title">{{ exam.examName }}</div>
            <div class="time"><i class="el-icon-time"></i> {{ exam.startTime }}~{{ exam.endTime }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="nums">
            <div class="item">
              <p>
                {{ 30 }}
                <label for="">分钟</label>
              </p>
              <span>考试时长</span>
            </div>
            <div class="item">
              <p>
                {{ exam.totalScore }}
                <label for="">分</label>
              </p>
              <span>总分</span>
            </div>
            <div class="item">
              <p>
                {{ exam.passScore }}
                <label for="">分</label>
              </p>
              <span>及格条件</span>
            </div>
            <div class="item">
              <p>
                {{ exam.passTime }}
                <label for="">次</label>
              </p>
              <span>剩余次数</span>
            </div>
          </div>
          <el-button type="primary" class="btn" @click="goExam">开始考试</el-button>
        </div>
      </div>
      <div class="module2">
        <ul>
          <li>
            <h5>考试规则</h5>
            <ul class="rule">
                <li class="rule-item flex">
                  <div class="times">切屏次数：<span>无限次</span></div>
                  <div class="tip">注：超过切屏次数规则系统会强制交卷。</div>
                </li>
                <li class="rule-item">
                  <p>考试记录查看：及格后可查看答卷。</p>
                </li>
                <li class="rule-item flex">
                  <div class="left">
                    强制交卷规则：
                  </div>
                  <div class="right">
                    <p>（1）进入答题界面开始计时, 倒计时结束未自主交卷则系统强制交卷；</p>
                    <p>（2）进入答题界面开始计时，未交卷退出答题界面，考试用时会继续计时，用时结束系统会强制交卷。</p>
                  </div>
                </li>
            </ul>
          </li>
          <li>
            <h5>考试说明</h5>
            <ul class="data">
              <li class="data-item flex">
                
              </li>
            </ul>
            <div class="empty-image">
              <img :src="empty" alt="">
              <p>暂无说明</p>
            </div>
          </li>
          <li>
            <h5>考试记录</h5>
            <ul class="data">
              <li class="data-item flex">
              </li>
            </ul>
            <div class="empty-image">
              <img :src="empty" alt="">
              <p>暂无说明</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import empty from "@/assets/image/no_audition.png"
export default {
  data () {
    return {
      empty:empty,
      "exam":{
            "paperId":5071,
            "examName":"测",
            "intro":"",
            "startTime":' 2023-06-19 00:00',
            "endTime":'2023-07-26 23:59',
            "limitTime":1800,
            "passLine":60,
            "passScore":19.2,
            "totalScore":32,
            "totalNum":0,
            "ruleScore":0,
            "ruleNum":0,
            "score":0,
            "passTime":0,
            "submitTime":1687277592,
            "questionRandom":1,
            "certificateId":0,
            "certificateName":"",
            "templatePath":"",
            "examTimes":-1,
            "fillblankReview":2,
            "answerReview":1,
            "anonymous":2,
            "switchNum":-2,
            "openFace":4,
            "openExam":2,
            "allowCopy":0,
            "viewAnswer":2,
            "myTimes":1,
            "status":2,
            "submitType":5,
            "historys":[
                {
                    "submitId":72081,
                    "reexamId":0,
                    "startTime":1687275774,
                    "useTime":1800,
                    "switchNum":0,
                    "submitTime":1687277592,
                    "score":0,
                    "submitType":5,
                    "status":2,
                    "signImage":""
                }
            ],
            "openSign":2
        },
        "isOverdue":0,
        "openFace":4,
        "taskType":10,
        "type":3
    };
  },
  methods:{
    goExam(){
      this.$router.push({
        path:'/exam/paper'
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.exam-detail{
  .bg{
    width: 100%;
    height: 300px;
    background: linear-gradient(90deg,#272f46,rgba(39,47,70,.8));;
  }
  .main{
    position: relative;
    left: 50%;
    margin-left: -600px;
    top: -180px;
    .module1{
      width: 100%;
      background-color: #fff;
      padding: 40px 35px;
      border-radius: 6px;
      box-sizing: border-box;
      .title{
        max-width: 750px;
        font-size: 32px;
        line-height: 48px;
        min-height: 48px;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 10px;
        font-weight: 600;
      }
      .time{
        font-size: 20px;
        color: #666;
      }
      .bottom{
        margin-top: 30px;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        border-top: 1px solid #e6e6e6;
        .nums{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          width: 700px;
          position: relative;
          .item{
            flex: 1;
            position: relative;
            text-align: center;
            color: #202020;
            p{
              font-size: 36px;
              font-weight: 700;
              color: #266fff;
              margin: 0;
              letter-spacing: -.04em;
              label{
                letter-spacing: -.04em;
                padding-left: 10px;
                font-size: 12px;
                color: #999;
                font-weight: 600;
              }
            }
            span{
              font-size: 14px;
            }
            &::after{
              content: '';
              position: absolute;
              right: 0;
              top: 25px;
              width: 2px;
              height: 10px;
              background: #7b85a7;
            }
            &:last-child:after{
              content: '';
              width: 0;
              height: 0;
            }
          }
        }
        .btn{
          width: 160px;
          height: 50px;
          background: #266fff;
          font-size: 22px;
        }
      }
    }
    .module2{
      margin-top: 20px;
      width: 100%;
      background-color: #fff;
      padding: 20px 28px;
      border-radius: 6px;
      box-sizing: border-box;
      ul{
        li{
          h5{
            position: relative;
            margin: 0;
            padding: 20px 12px;
            font-size: 20px;
            font-weight: 550;
            color: #333;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 37%;
              width: 4px;
              height: 20px;
              border-radius: 2px;
              background-color: #266fff;;
            }
          }
          .empty-image{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;
            img{
              width: 169px;
              height: 120px;
            }
            p{
              color:#202020;
              font-size: 14px;
              line-height: 22px;
            }
          }
          .rule{
            padding-left: 26px;
            .rule-item{
              font-size: 14px;
              line-height: 22px;
              margin-bottom: 8px;
              position: relative;
              &::before{
                content: '●';
                position: absolute;
                top: 0;
                left: -16px;
                color: #666;
              }
              .times{
                width: 240px;
              }
              .right{
                p{
                  line-height: 24px;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>